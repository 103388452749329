import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import GatsbyLink from '../GatsbyLink'
import { FaPhone, FaSearch } from 'react-icons/fa'

import { Logo } from '../../../theme/Logo'
import HeaderNav from '../HeaderNav'
import './Header.scss'

const Header = data => {
  const [isMobileOpen, setMobileOpen] = useState(false)
  const {
    abovePrimaryNavigation,
    primaryNavigation,
    siteSettings,
    wordpressWpSettings,
    productCategories,
  } = data.data
  const { options } = siteSettings
  const { phone } = options.locations[0]
  const { baseUrl } = wordpressWpSettings
  const { items: navItems } = primaryNavigation
  const { items: aboveNavItems } = abovePrimaryNavigation

  const toggleMobileNavigation = () => {
    setMobileOpen(previousState => !previousState)
  }

  return (
    <header>
      <div className="header-container">
        <div className="wrap">
          <div className="inner">
            <div className="logo">
              <Logo />
            </div>
            <div className="smallOnly-navigation">
              {options.contactNumberHeader && (
                <div className="phone">
                  <a
                    href={`tel:${phone.replace(/[^A-Z0-9]/gi, '')}`}
                    className="control-call"
                    aria-label="Call Now"
                  >
                    <FaPhone size="16px" />
                    <span className="d-sm-none">{phone}</span>
                  </a>
                </div>
              )}
              <button
                type="button"
                className={`control-nav icon-hamburger ${isMobileOpen ? 'active' : ''
                  }`}
                onClick={toggleMobileNavigation}
                aria-label="Toggle Menu"
              >
                <span className={`burger ${isMobileOpen ? 'active' : ''}`} />
              </button>
            </div>
            <div
              className={`navigation-container ${isMobileOpen ? 'active' : ''}`}
            >
              {options.navigationAbovePrimaryMenu && (
                <div className="above-primary-container">
                  <HeaderNav
                    className="above-primary-navigation"
                    navItems={aboveNavItems}
                    baseUrl={baseUrl}
                  />
                  {options.contactNumberHeader && (
                    <div className="phone">
                      <a
                        href={`tel:${phone.replace(/[^A-Z0-9]/gi, '')}`}
                        className="control-call"
                        aria-label="Call Now"
                      >
                        <FaPhone size="16px" />
                        {phone}
                      </a>
                    </div>
                  )}
                </div>
              )}
              <div className="primary-navigation-container">
                <HeaderNav
                  className="primary-navigation"
                  navItems={navItems}
                  baseUrl={baseUrl}
                  megaMenu
                />

                <GatsbyLink
                  className="search-icon"
                  to="/search/"
                  aria-label="Search"
                  decode={false}
                >
                  <FaSearch size={18} />
                </GatsbyLink>

                <GatsbyLink
                  className="quote"
                  to="/quote/"
                  aria-label="Get a quick quote"
                  decode={true}
                >
                  Instant quote
                </GatsbyLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        abovePrimaryNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "above-primary-navigation" }
        ) {
          items {
            title
            url
            classes
            object_id
            slug: object_slug
            target
            children: wordpress_children {
               title
               url
               classes
               object_id
               slug: object_slug
               target
            }
          }
        }
        primaryNavigation: wordpressWpApiMenusMenusItems(slug: {eq: "primary-navigation"}) {
          items {
            title
            url
            classes
            target
            description
            type
            slug: object_slug
            object_id
            acf {
              menu_image {
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 300, quality: 70) {
                      ... GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
            children: wordpress_children {
              title
              url
              classes
              target
              type
              slug: object_slug
              object_id
              acf {
                menu_image {
                  localFile {
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 300, quality: 70) {
                        ... GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
              children: wordpress_children {
                title
                url
                classes
                target
                type
                slug: object_slug
                object_id
              }
            }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            navigationAbovePrimaryMenu
            socialLinksHeader
            contactNumberHeader
            invertHeaderColours
            showSearchIconInMainNavigation
            locations {
              addressDisplay
              email
              phone
            }
          }
        }
        wordpressWpSettings {
          siteUrl
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)
